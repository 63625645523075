
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getComponent, sleep } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';

import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
// eslint-disable-next-line import/extensions
import cellRender from '@/views/agGrid/CellRenderWorkTicket.js';
// eslint-disable-next-line import/extensions
import numericEditor from '@/views/agGrid/NumericEditor.js';
import tasqsListModule from '@/store/modules/tasqsListModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import { Debounce } from 'vue-debounce-decorator';
import uniqid from 'uniqid';
import { SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import GenericMixin from '@/lib/mixins/GenericMixin';
import { mixins } from 'vue-class-component';
import metaDataModule from '@/store/modules/metaDataModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    PopupView: () => getComponent('tasqsCommon/PopupView'),
    WellSignalsView: () => getComponent('tasqs/WellSignalsView'),
    WorkTicketSidebar: () => getComponent('workTickets/WorkTicketSidebar'),
    MessageToaster: () => getComponent('tasqsCommon/MessageToaster'),
    DateRangePicker,
    numericEditor,
    draggable,
    AgGridVue,
    cellRender,
  },
})
export default class WorkTicketDetail extends mixins(GenericMixin) {
  @Prop({ type: Boolean, required: false, default: false }) isPopup?: boolean;

  get activeWorkTicket() {
    return tasqsListModule.activeWorkTicket;
  }

  test = new Date();

  loadingSidebar = false;

  hideDateSection = false;

  loadingWorkTicket = false;

  loadingWorkTicketDailyData = false;

  showAddNewCustomDailyCost = false;

  showLinkSharePopup = false;

  workTicketStatus = 'Open';

  workTicket: any = {};

  selectedDailyCostDay: any = {};

  requestDetails = '';

  isUpdatingStatus = false;

  showMessageToaster = false;

  showWellSignalPopup = false;

  showSignalTasqTooltip = false;

  columnDefs: any = [];

  getRowNodeId: any = null;

  context: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

  defaultColDef = {
    initialWidth: 250,
    sortable: true,
    filter: true,
    editable: true,
    autoHeight: true,
    cellStyle: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      overflow: 'visible',
      'white-space': 'normal',
    },
    resizable: true,
  };

  get blockedReasons() {
    return metaDataModule.blockedReasonOptions;
  }

  // methods

  async changeDatePicker() {
    this.hideDateSection = true;

    this.$nextTick(() => {
      this.hideDateSection = false;
    });

    await this.saveWorkTicket();
  }

  async handleStatusChange() {
    if (this.workTicket.status !== 'Open') {
      this.startConfetti();
      this.workTicket.status = 'Complete';
    } else {
      this.workTicket.status = 'In Progress';
    }
    await this.saveWorkTicket();
  }

  closeDialog() {
    this.showAddNewCustomDailyCost = false;
  }

  @Debounce(500)
  async handleStatusChangeDropdown() {
    if (this.workTicket.status === 'Complete') {
      this.startConfetti();
    }
    this.$nextTick(async () => {
      await this.saveWorkTicket();
    });
  }

  copyLink() {
    navigator.clipboard.writeText(window.location.href);
    this.showLinkSharePopup = false;
  }

  closeShareableDialog() {
    this.showLinkSharePopup = false;
  }

  dailyWorkTableData: any = [
    {
      dailyDate: new Date().toLocaleDateString(),
      predictionID: this.workTicket.id,
      twentyFourHourSummary: '',
      nextTwentyFourHourSummary: '',
      hoursSpentToday: null,
      dailyCostTable: [],
    },
  ];

  async addNewCustomDailyCost(date) {
    const offset = new Date().getTimezoneOffset() / 60;
    let updatedDate: any = new Date(date);
    updatedDate.setTime(updatedDate.getTime() + offset * 60 * 60 * 1000);
    updatedDate = updatedDate.toDateString();
    this.showAddNewCustomDailyCost = false;
    if (
      this.dailyWorkTableData.find(
        (data) => data.dailyDate === new Date(updatedDate).toDateString()
      )
    ) {
      this.selectedDailyCostDay = this.dailyWorkTableData.find(
        (data) => data.dailyDate === new Date(updatedDate).toDateString()
      );

      return;
    }
    const newCostTable = {
      dailyDate: updatedDate,
      predictionID: this.workTicket.id,
      twentyFourHourSummary: '',
      nextTwentyFourHourSummary: '',
      hoursSpentToday: null,
      dailyCostTable: [],
    };
    this.dailyWorkTableData.push(newCostTable);
    this.selectedDailyCostDay = newCostTable;
    // console.log(this.dailyWorkTableData);
    this.dailyWorkTableData.sort(
      (a, b) => new Date(b.dailyDate).getTime() - new Date(a.dailyDate).getTime()
    );
    await this.updateDailyCostTable();
  }

  @Debounce(200)
  async removeRow(id) {
    const { dailyCostTable } = this.dailyWorkTableData.find(
      (data) => data.dailyDate === this.selectedDailyCostDay.dailyDate
    );
    this.dailyWorkTableData = this.dailyWorkTableData.map((data) => {
      if (data.dailyDate === this.selectedDailyCostDay.dailyDate) {
        // eslint-disable-next-line no-param-reassign
        data.dailyCostTable = dailyCostTable.filter((row) => row.id !== id);
      }
      return data;
    });
    await this.stopEditDailyWorkTable();
  }

  cellClick() {
    // console.log(e);
  }

  addNewItemToDailyCostTable() {
    this.dailyWorkTableData.map((eachDayData) => {
      if (this.selectedDailyCostDay.dailyDate === eachDayData.dailyDate) {
        eachDayData.dailyCostTable.push({
          id: uniqid(),
          vendor: '',
          costCodes: '',
          amount: '',
          notes: '',
        });
      }
      return eachDayData;
    });
    const dailyCostTableUI = document.querySelector('#daily-cost-table .ag-root-wrapper-body')!;
    if (dailyCostTableUI) {
      const scrollableBodyUI = dailyCostTableUI.querySelector(
        '.ag-body-viewport.ag-layout-normal'
      )!;
      if (scrollableBodyUI) {
        this.$nextTick(() => {
          scrollableBodyUI.scrollTo(0, (scrollableBodyUI.scrollHeight + 1) * 2);
        });
      }
    }
  }

  async onGridReady(params) {
    this.gridApi = params.api;
    // this.rowData = [];
    this.columnDefs = [];
    this.gridColumnApi = params.columnApi;
    this.gridApi.hideOverlay();

    this.columnDefs = [
      {
        field: 'vendor',
        cellClass: 'noborder',
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          lineHeight: 1.6,
          fontSize: '12px',
          fontWeight: '300',
          color: 'white',
        },
        sortable: true,
        filter: true,
        width: 250,
        colId: 'vendor',
        headerName: 'Vendors',
        cellEditorPopup: true,
      },
      {
        field: 'costCodes',
        width: 150,
        colId: 'CostCodes',
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          lineHeight: 1.6,
          fontSize: '12px',
          fontWeight: '300',
          color: 'white',
        },
        cellClass: 'noborder',
        headerName: 'Cost Codes',
        cellEditorPopup: true,
      },
      {
        field: 'amount',
        width: 150,
        colId: 'amount',
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          lineHeight: 1.6,
          fontSize: '12px',
          fontWeight: '300',
          color: 'white',
        },
        cellClass: 'noborder',
        headerName: 'Amount $',
        cellEditor: 'numericEditor',
        cellEditorPopup: true,
      },
      {
        field: 'notes',
        width: 880,
        colId: 'notes',
        cellStyle: {
          overflow: 'visible',
          lineHeight: 1.6,
          fontSize: '12px',
          fontWeight: '300',
          color: 'white',
          padding: '0px',
          margin: '0px',
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        },
        cellClass: 'noborder',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: 1000,
        },
        cellEditorPopup: true,

        headerName: 'Notes',
      },
      {
        field: '',
        colId: 'actions',
        sortable: true,
        width: 100,
        pinned: 'right',
        cellClass: 'noborder',
        suppressToolPanel: true,
        resizable: false,
        cellStyle: {
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          overflow: 'visible',
          'white-space': 'normal',
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
        },
        suppressRowTransform: true,

        editable: false,
        cellRendererFramework: 'cellRender',
      },
    ];

    this.gridColumnApi.autoSizeColumns();
  }

  startConfetti() {
    this.showMessageToaster = true;
    // @ts-ignore
    this.$confetti.start({ defaultDropRate: 25 });

    setTimeout(() => {
      // @ts-ignore
      this.$confetti.stop();
    }, 2500);
  }

  updateWorkTicketDetails() {
    this.loadingSidebar = true;
    this.workTicket = { ...tasqsListModule.activeWorkTicket };
    this.$nextTick(() => {
      this.loadingSidebar = false;
    });
  }

  async created() {
    if (this.$route.name === 'WorkTicketCalendar') {
      await this.fetchData(this.activeWorkTicket?.id);
    }
  }

  beforeMount() {
    this.context = { componentParent: this };
  }

  async beforeDestroy() {
    if (this.allowDailyCostSaving) {
      await this.postDataToBackend();
      await this.updateDailyCostTable();
    }
  }

  async updateFiles(files) {
    this.workTicket.files = files;
    await this.saveWorkTicket();
  }

  async updateChecklist(list) {
    this.workTicket.detailedSteps = list;
    await this.saveWorkTicket();
  }

  async updatePriority(data) {
    this.workTicket.priority = data;
    await this.saveWorkTicket();
  }

  async updateAssignee(data) {
    this.workTicket.assignee = data;
    await this.saveWorkTicket();
  }

  async updateStartDate(data) {
    this.workTicket.ticketStartDate = data;
    await this.saveWorkTicket();
  }

  async updateEndDate(data) {
    this.workTicket.ticketDueDate = data;
    await this.saveWorkTicket();
  }

  async fetchWorkTicketDetails({ predictionID }) {
    this.loadingSidebar = true;
    await tasqsListModule.getTasq({
      PredictionID: predictionID,
      Type: 'workTicket',
    });
    this.updateWorkTicketDetails();
  }

  allowDailyCostSaving = false;

  async fetchDailyWorkTicketData({ predictionID }) {
    const dailyWorkTicketData = await tasqsListModule.getDailyWorkTicketData({
      predictionID,
    });
    let allDaysData: any = [];
    let todayData: any = [];
    if (dailyWorkTicketData && dailyWorkTicketData.length) {
      // eslint-disable-next-line radix
      todayData = dailyWorkTicketData.filter(
        (data) => new Date(data.dailyDate).toLocaleDateString() === new Date().toLocaleDateString()
      );
      // eslint-disable-next-line radix
      allDaysData = dailyWorkTicketData.filter(
        (data) => new Date(data.dailyDate).toLocaleDateString() !== new Date().toLocaleDateString()
      );
    }
    if (!todayData.length) {
      todayData = [
        {
          dailyDate: new Date().toDateString(),
          predictionID: this.workTicket.id,
          twentyFourHourSummary: '',
          nextTwentyFourHourSummary: '',
          hoursSpentToday: null,
          dailyCostTable: [],
        },
      ];
    }
    this.dailyWorkTableData = todayData.concat(allDaysData);
    if (this.dailyWorkTableData.length) {
      this.dailyWorkTableData.sort(
        (a, b) => new Date(b.dailyDate).getTime() - new Date(a.dailyDate).getTime()
      );
    }
    // eslint-disable-next-line prefer-destructuring
    this.selectedDailyCostDay = this.dailyWorkTableData[0];

    await sleep(1000);
    this.allowDailyCostSaving = true;
  }

  async updateDailyCostTable() {
    const dailyCost = this.dailyWorkTableData.find(
      (data) => data.dailyDate === this.selectedDailyCostDay.dailyDate
    );
    //  console.log();
    await tasqActionsModule.putDailyWorkTicketData({
      ...dailyCost,
      predictionID: this.workTicket.id,
    });
  }

  @Watch('$route.params.id', { immediate: true })
  async onTasqRouteIdChange(id: string) {
    if (id) {
      this.showWellSignalPopup = false;
      this.loadingWorkTicket = true;
      await sleep(2000);
      this.allowDailyCostSaving = false;
      this.fetchData(id);
    }
  }

  async fetchData(id) {
    try {
      this.loadingWorkTicket = true;
      this.loadingWorkTicketDailyData = true;
      await sleep(100);
      await accountModule.getReassignmentList({ useExisting: true });
      await this.fetchWorkTicketDetails({ predictionID: id });
      this.loadingWorkTicket = false;
      await this.fetchDailyWorkTicketData({ predictionID: id });
      this.loadingWorkTicketDailyData = false;
    } catch (error) {
      this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
        text: 'It is not possible to load data at this moment, please try later',
        error: true,
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  @Debounce(2000)
  async stopEditDailyWorkTable() {
    if (this.allowDailyCostSaving) {
      this.showMessageToaster = true;
      await this.updateDailyCostTable();
    }
  }

  @Debounce(2000)
  async saveWorkTicket() {
    try {
      await this.postDataToBackend();
      this.showMessageToaster = true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
        text: 'Ticket Saving Failed!',
        error: true,
      });
    }
  }

  async postDataToBackend() {
    await tasqActionsModule.updateWorkTicket({
      id: this.workTicket.id,
      assetType: this.workTicket.level,
      well: this.workTicket.wellName,
      status: this.workTicket.status,
      jobType: this.workTicket.jobType,
      requestDetails: this.workTicket.requestDetails,
      files: this.workTicket.files,
      checklist: this.workTicket.detailedSteps,
      priority: this.workTicket.priority,
      assignedToUser: this.workTicket.assignee || accountModule.user.email,
      statusStartDate: this.workTicket.statusStartDate,
      statusEndDate: this.workTicket.statusEndDate,
      ticketStartDate: this.workTicket.ticketStartDate,
      ticketDueDate: this.workTicket.ticketDueDate,
      reason: this.workTicket.reason,
    });

    tasqsListModule.setIsLoadingTasqs(true);
    tasqsListModule.updateWorkTicketDetails({ ...this.workTicket });
    this.$nextTick(() => {
      tasqsListModule.setIsLoadingTasqs(false);
    });
  }

  setVerticalListPinnedValue() {
    tasqsListModule.setIsVerticalListUnPinned(!tasqsListModule.isVerticalListUnPinned);
  }

  setVerticalListHovering(state) {
    tasqsListModule.setIsVerticalListHovering(state);
  }
}
