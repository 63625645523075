import Vue from 'vue';

export default Vue.extend({

  data() {
    return {
      showBtn: false,
      width: window.innerWidth,
    };
  },
  created() {
    if (this.width < 700) {
      this.showBtn = true;
    }
  },
  methods: {
    deleteRow() {
      const objectID = this.params.data.id;
      const gridApi = this.params.api;
      // console.log(gridApi.getRowNode(objectID))
      this.params.context.componentParent.removeRow(objectID);

    //   this.params.api.updateRowData({ remove: [rowNode ] });
    },

  },
  template: `
      <div class="text-center"   @click="deleteRow()">
      <span
      @click="deleteRow()"
      style="vertical-align: middle; line-height: normal;"
      class="material-icons cursor-pointer text-tasqNeutral300 hover:text-tasqAccentError"
    >
      delete
    </span>
    </div>
    `,
});
